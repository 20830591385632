import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import BlogHeader from './BlogHeader';
import BlogListing from './BlogListing';

const BlogContent = ({ newsCategories, newsItems, initialCategory }) => {
  const [currentCategory, setCurrentCategory] = useState(initialCategory || null); // Set from pageContext
  const [paginatedNews, setPaginatedNews] = useState(newsItems.slice(0, 8)); // Load initial 8 items
  const [page, setPage] = useState(1); // Start with page 1

  // Reset when the category changes
  useEffect(() => {
    const initialNews = newsItems.slice(0, 8); //Reset
    setPaginatedNews(initialNews);
    setPage(1); // Reset page to 1 when category changes
  }, [currentCategory, newsItems]);

  const handleShowMore = () => {
    const startIndex = page * 8; // Calculate the start index for the next set of items
    const newPaginatedNews = newsItems.slice(startIndex, startIndex + 8); // Get the next 8 items
    setPaginatedNews((prevNews) => [...prevNews, ...newPaginatedNews]); // Append to current list
    setPage(page + 1); // Increment the page after appending
  };

  return (
    <div className="blog-content-wrapper sec-pdb-120">
      <Container>
        <BlogHeader
          categories={newsCategories}
          currentCategory={currentCategory}
          setCategory={setCurrentCategory}
        />

        <BlogListing news={paginatedNews} />

        {paginatedNews.length > 0 && paginatedNews.length < newsItems.length && (
            <div className="d-flex justify-content-center load-more-btn">
                <Button variant="outline" onClick={handleShowMore} className="show-more-btn">
                    Show More
                </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default BlogContent;