import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import { GetURL } from "../common/site/functions";
import { Link } from "@StarberryUtils";
import "./PageHeader.scss";

const PageHeader = (props) => {
  return (
    <section className="page-header sec-pdy-80">
      <Container>
         <Row>
            <Col xl={8}>      
              {props?.title && <h1>{props?.title}</h1> }
              {props?.content && <div className="content">
                {HTMLReactParser(props?.content.replace(/<br\s*\/?>/gi, ''))}
              </div>}
              {(props.cta_1 && props.cta_1_link) || (props.cta_2 && props.cta_2_link) ? (
                <div className={`${props?.mobile_sticky ? 'd-none d-md-flex' : 'd-flex'} btn-wrapper`}>
                  {props.cta_1 && props.cta_1_link && (
                    <Link to={`/${GetURL(props.cta_1_link.id)}`} className="btn btn-primary">
                      {props.cta_1}
                    </Link>
                  )}
                  {props.cta_2 && props.cta_2_link && (
                    <Link to={`/${GetURL(props.cta_2_link.id)}`} className="btn btn-secondary">
                      {props.cta_2}
                    </Link>
                  )}
                </div>
              ) : null}
            </Col>
         </Row>
      </Container>
    </section>
  );
};

export default PageHeader;
