import React from 'react';
import { Link } from 'gatsby';
import { LATEST_NEWS_PAGE_URL } from "../common/site/constants";
import "../../components/Home/Banner/NewsHeader.scss";


const BlogHeader = ({ categories, currentCategory, setCategory }) => {

  const handleCategoryChange = (category) => {
    setCategory(category);
  };

  return (
    <div className="news-header">
      <div className="tabing-list-wrap">
        <ul className="tabing-list">
          {/* "All Posts" category */}
          <li
            className={`${currentCategory === null ||  currentCategory === "all" ? 'active' : ''} text-xs`} 
            onClick={() => handleCategoryChange("all")} // Set null for "All Posts"
          >
            <Link to={`${LATEST_NEWS_PAGE_URL.alias}`}>All Posts</Link>
          </li>
          {/* Loop through categories */}
          {categories.map((category) => (
            <li
              key={category.id}
              className={`${currentCategory === category.URL ? 'active' : ''} text-xs`}
            >
              <Link
                to={`${LATEST_NEWS_PAGE_URL.alias}/${category.URL}/`}
                onClick={() => handleCategoryChange(category.URL)}  // Set the selected category
              >
                {category.Name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogHeader;