import React, { useState, useEffect, PropTypes } from "react"
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import PageHeader from "../components/PageHeader/PageHeader";
import BlogContent from "../components/BlogContent/BlogContent";
import Contact from "../components/Home/ContactUs/Contact";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/default-template.scss";


const BlogLandingPageTemplate = (props) => {
    const { category, category_name } = props.pageContext
    
    const [state, setState] = React.useState({
        showMenu: false
      })
    
      const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu })
      }
    
      const GQLPage = props.data.glstrapi?.article;
      const Page_Modules = props.data.glstrapi?.article?.Modules;
      const newsCategories = props.data.glstrapi?.newsCategories || []
      const newsItems = props.data.glstrapi?.newsItems || []
      
      const FooterBlockModule = Page_Modules?.find(
        (module) => module.type === "GLSTRAPI_ComponentModuleFooterBlock"
      );
    
      // Filter news items based on the category from context
      const filteredNews = category === "all" 
        ? newsItems 
        : newsItems.filter(item => item.news_category && item.news_category.URL === category);
    
      let custom_css = ''
      if (GQLPage?.Custom_CSS_Classname) {
        custom_css = GQLPage.Custom_CSS_Classname
      }
      const layoutClass = GQLPage?.Layout ? `page-layout-${GQLPage?.Layout.toLowerCase().replace(/\s+/g, '-')}` : '';
  
      var metaTitle = GQLPage.Meta_Title;
      var metaDesc = GQLPage.Meta_Description;
    
      // Special case for "living-in-dubai"
        if (category === "living-in-dubai") {
            metaTitle = 'News and insights about living in Dubai';
            metaDesc = 'For the most up to date news and insightful analysis about living in Dubai, from our team of real estate professionals.';
        } else {
            metaTitle = category_name && category_name !== "all"
            ? `Property ${category_name} news Dubai`
            : 'Property news Dubai';
        
          metaDesc = category_name && category_name !== "all"
            ? `Get all the latest real estate ${category_name} news in Dubai right here at Dacha Real Estate. We cover everything from new property developments to market trends and more!`
            : 'Get all the latest real estate news in Dubai right here at Dacha Real Estate. We cover everything from new property developments to market trends and more!';
        
        }

      return (
    <div className={`blog-landing-page-template page-news-insights-wrap news-landing-page ${custom_css} ${layoutClass}`}>
      <SEO title={metaTitle} description={metaDesc} location={props.location} />
      <div className={`${state.showMenu ? "open-search-block" : ""} static-page`}>
        <Header homeArticleId={props.pageContext} location={props.location} showMenu={state.showMenu} handlerClick={handlerClick} fixed={true}  TransparentHeader= '' />  
        <Breadcrumb data={GQLPage.all_menus ? GQLPage.all_menus[0] : ''} page={GQLPage.Pagename} /> 
        <PageHeader
                        content={GQLPage.Header_Content}
                        title={GQLPage.Header_Title}
                        cta_1={GQLPage.Header_CTA_1_Label}
                        cta_2={GQLPage.Header_CTA_2_Label}
                        cta_1_link={GQLPage.Header_CTA_1_Link}
                        cta_2_link={GQLPage.Header_CTA_2_Link}
                        mobile_sticky={GQLPage.Show_CTA_Sticky_Mobile}
                    />
              <BlogContent 
                  newsCategories={newsCategories} 
                  newsItems={filteredNews}
                  initialCategory={category}
              />
             {FooterBlockModule &&
                <>
                  <Contact {...FooterBlockModule} id={GQLPage._id} imagetransforms={GQLPage.imagetransforms} /> 
                </>
              }
                                
            <Footer popularSearch={GQLPage.Popular_Search} />
            {GQLPage.Show_CTA_Sticky_Mobile && 
            <StickyFooter cta_1={GQLPage.Header_CTA_1_Label}
                        cta_2={GQLPage.Header_CTA_2_Label}
                        cta_1_link={GQLPage.Header_CTA_1_Link}
                        cta_2_link={GQLPage.Header_CTA_2_Link}
                        search={GQLPage.Show_Search_Type} />
            }
      </div>
    </div>
      )
};
export default BlogLandingPageTemplate;

export const pageQuery = graphql`
  query BlogPageQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
          _id
          Pagename
          Show_Search_Type
          Meta_Title
          Meta_Description
          Layout
          Header_Title
          Header_Content
          Header_CTA_2_Label
          Header_CTA_1_Label
          Show_CTA_Sticky_Mobile
          Custom_CSS_Classname  
          imagetransforms
          Popular_Search
          Header_CTA_1_Link {
            id
          }
          Header_CTA_2_Link {
            id
          }
          Modules { 
            ... on GLSTRAPI_ComponentModuleFooterBlock {
              id
              type: __typename
              FooterBlockTitle: Title
              Content
              CTA_1_Label
              CTA_1_Action
              CTA_1_Link {
                id
                Link_Type
              }
              CTA_2_Label
              CTA_2_Action
              CTA_2_Link {
                id
                Link_Type
              }                
              Image {
                  alternativeText
                  url
                  formats
              }
            }                    
          }
          all_menus {
            Label
            Main_Parent {
              Label
              id
              URL
            }
            Sub_Parent {
              Label
              URL
              id
            }
          } 
        }
        newsCategories(sort:"sort:asc", where:{Publish:true}) {
            Name
            URL
            id
        }
        newsItems(sort: "createdAt:desc", where:{Publish:true}) {
            id        
            Title
            Date
            Embed_Video_URL
            Featured
            Meta_Description
            Meta_Title
            URL
            Tile_Image {
            alternativeText
            url
            }
            news_category {
                Name
                URL
            }
            imagetransforms
        }
      }
  }
`